.footer__copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 1.3em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__alvp {
  border-radius: 8px;
  padding: 2px 5px;
  font-family: "Josefin Sans", sans-serif !important;
}

.footer h3 {
  font-family: "Dancing Script", cursive !important;
  text-align: center !important;
  letter-spacing: 0.2em;
  font-size: 1.8em !important;
  color: white !important;
}

.alvp__icon {
  animation: heartPulse 2s infinite linear;
}

@keyframes heartPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
