:root {
  --backgroundColor: #faf3d7;
}

.productitem {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border: 1px solid white;
  border-radius: 8px;
  padding: 12px;
  font-size: 1.3em;
  background-color: grey;
  position: relative;
}

.productitem-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productitem-header h3 {
  margin-bottom: 8px;
  font-size: 1.2em !important;
  margin-right: 5px !important;
  text-transform: uppercase !important;
}

.productitem-header span {
  margin-bottom: 8px;
  font-size: 1.2rem !important;
}

.price {
  align-self: baseline;
  font-weight: bold;
  display: inline-block;
  padding: 3px;
  border: 1px solid #2b2b29;
  background: var(--backgroundColor);
  border-radius: 8px;
}

.bosschoice {
  position: absolute;
  top: -30px;
  right: -15px;
  border: 1px solid transparent;
  background: pink;
  border-radius: 100%;
}

.region {
  font-weight: bold;
  display: inline-block;
  width: fit-content;
  margin-bottom: 7px;
  font-size: 0.9em;
  padding: 2px 3px;
  border: 1px solid #2b2b29;
  background: var(--backgroundColor);
  border-radius: 8px;
  text-transform: uppercase;
}

.description {
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  padding: 6px;
  line-height: 1.5;
  letter-spacing: 1.5;
  border: 1px solid #2b2b29;
  background: var(--backgroundColor);
  border-radius: 8px;
}
